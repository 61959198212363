@tailwind base;
@tailwind components;
@tailwind utilities;


/* 
Google login button rendering fixes
component: Components/Login/GoogleLoginButton
*/
#google-login-btn>div>iframe {
    height: 50px !important;
    width: 325px !important;
}

/*
Hide spinner for input type number
*/
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

/* Custom Scrollbar */
::-webkit-scrollbar { 
    display: none;
}

/* 
Hide and show menu item actions on Menu -> Menu Item list
*/
.menuItem .menuItemActions {
    visibility: hidden;
}

.menuItem:hover .menuItemActions {
    visibility: visible;
}